import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const SEO = ({
               title = null,
               description = null,
               pathname = null,
             }) => (
  <StaticQuery
    query={graphql`
      query SEOZopimQuery {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
          }
        }
      }
    `}
    render={({
               site: {
                 siteMetadata: {
                   defaultTitle,
                   defaultDescription,
                   siteUrl,
                 },
               },
             }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        siteUrl: `${siteUrl}${pathname || '/'}`,
      };

      //console.log('Seo rendering...');

      return (
        <Helmet title={seo.title} defer={false}>
          <html key="app-lang" lang="ms"/>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="description" content={seo.description}/>
          <meta key="app-viewport" name="viewport" content="initial-scale=1.0, width=device-width"/>
          <meta name="robots" content="all,noodp"/>
          <meta name="googlebots" content="all,noodp"/>
          <meta name="revisit-after" content="1 days"/>
          <link rel="alternate" href="https://en.oppa888.com/" hrefLang="en"/>
          <link rel="alternate" href="https://vi.oppa888.com" hrefLang="vi-vn"/>
          <link rel="alternate" href="https://hi.oppa888.com" hrefLang="hi"/>
          <link rel="alternate" href="https://id.oppa888.com " hrefLang="id-id"/>
          <link rel="alternate" href="https://ms.oppa888.com" hrefLang="ms"/>
          <script type="text/javascript">
            {`
              window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=d.createElement(s),e=d.getElementsByTagName(s)[0];
              z.set=function(o){z.set._.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
              $.src="//v2.zopim.com/?63SvbtbNwSUA1XY9Mtye34WchkzsZWjL";z.t=+new Date;$.type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");
              $zopim(function() {
                //$zopim.livechat.hideAll();
              $zopim.livechat.window.show();
            });
            `}
          </script>

        </Helmet>
      );
    }}
  />
);

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
};

export default SEO;